<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined class="d-md-flex flex-md-column" :loading="loading">
        <v-card-text class="text--primary">
          <!-- page header -->
          <div class="d-flex align-center justify-space-between mb-6">
            <h3 class="display-1">Edit Package</h3>
            <span class="title font-weight-regular">{{
              item.id.toUpperCase()
            }}</span>
            <v-btn color="primary" text to="/packages" exact
              ><v-icon left>mdi-arrow-left</v-icon> Back to Packages</v-btn
            >
          </div>
          <!-- page header -->
          <!-- form -->
          <v-form ref="form" @submit.prevent="onSubmit">
            <!-- images -->
            <v-row>
              <v-col cols="12">
                <v-toolbar flat dense>
                  <v-toolbar-title>{{
                    item.images.length ? "Images" : "Add 4 Images Total"
                  }}</v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items v-if="item.images && item.images.length < 4">
                    <v-file-input
                      style="min-width: 400px"
                      accept="image/*"
                      label="Add 4 Images Total"
                      @change="onAddImg($event)"
                      :rules="imageRules"
                    ></v-file-input>
                  </v-toolbar-items>
                </v-toolbar>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                v-for="(item, index) in item.images"
                :key="index"
              >
                <v-card outlined>
                  <v-img :src="item.downloadUrl" cover height="300">
                    <div
                      v-if="item.addOverlayText && item.overlayText"
                      class="text-center title white--text px-4 py-2 text-uppercase"
                      style="
                        background: rgba(0, 0, 0, 0.75);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                      "
                    >
                      {{ item.overlayText }}
                    </div>
                  </v-img>
                  <v-card-text class="text--primary">
                    <v-row>
                      <v-col cols="12" class="body-1 font-weight-medium">
                        Name:
                        <span>{{ item.name }}</span>
                      </v-col>
                      <!-- add overlay text -->
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="[true, false]"
                          v-model.trim="item.addOverlayText"
                          label="Add Overlay Text *"
                          :rules="[
                            (v) => !!v || v === false || 'Field Required',
                          ]"
                        ></v-select>
                      </v-col>
                      <!-- add overlay text -->
                      <!-- overlay text -->
                      <v-col cols="12" v-if="item.addOverlayText">
                        <v-text-field
                          label="Overlay Text *"
                          v-model.trim="item.overlayText"
                          dense
                          :rules="[(v) => !!v || 'Field Required']"
                          counter
                          hint="Max 45 Characters"
                          @keypress="limitCharacterCount($event, 45)"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                      <!-- overlay text -->
                      <!-- download URL -->
                      <v-col cols="12" class="body-1">
                        <span class="d-block mb-2 font-weight-medium"
                          >Image URL:</span
                        >
                        <code v-html="item.downloadUrl" />
                      </v-col>
                      <!-- download URL -->
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      depressed
                      @click="onSortImg(index, index - 1)"
                      :class="index == 0 ? 'd-none' : ''"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      color="primary"
                      depressed
                      @click="onSortImg(index, index + 1)"
                      :class="
                        item.images
                          ? index == item.images.length - 1
                            ? 'd-none'
                            : ''
                          : ''
                      "
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn color="error" depressed @click="onDeleteImg(index)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-divider class="mt-5 mb-6" />
              </v-col>
            </v-row>
            <!-- images -->
            <v-row>
              <!-- packageCode -->
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  label="Package Code *"
                  v-model.trim="item.packageCode"
                  :rules="[(v) => !!v || 'Field Required']"
                  @input="formatPackageCode($event)"
                  :disabled="$route.params.id !== 'new' ? true : false"
                  :readonly="$route.params.id !== 'new' ? true : false"
                ></v-text-field>
              </v-col>
              <!-- package code -->

              <!-- destination -->
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Destination*"
                  dense
                  v-model.trim="item.destination"
                  :rules="[(v) => !!v || 'Field Required']"
                ></v-text-field>
              </v-col>
              <!-- destination -->

              <!-- roomType -->
              <v-col cols="12" sm="6" md="4">
                <v-select
                  :items="roomTypes"
                  item-text="title"
                  dense
                  item-value="id"
                  return-object
                  v-model.trim="item.roomType"
                  label="Room Type *"
                  :rules="[(v) => v.id !== '' || 'Field Required']"
                ></v-select>
              </v-col>
              <!-- roomType -->

              <!-- numberNights -->
              <v-col cols="12" sm="6" md="4">
                <v-select
                  dense
                  :items="numberNights"
                  item-text="title"
                  item-value="title"
                  v-model.trim="item.numberNights"
                  label="Number of Nights *"
                  :rules="[(v) => !!v || 'Field Required']"
                ></v-select>
              </v-col>
              <!-- numberNights -->

              <!-- cost to org -->
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Cost to Org. *"
                  dense
                  type="number"
                  v-model.trim="item.costToOrg"
                  @change="(v) => (item.costToOrg = parseInt(v))"
                  @keypress="limitNumberValues($event)"
                  :rules="[(v) => !!v || 'Field Required']"
                ></v-text-field>
              </v-col>
              <!-- cost to org -->

              <!-- max retail -->
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  label="Max Retail Value *"
                  type="number"
                  @change="(v) => (item.maxRetail = parseInt(v))"
                  v-model.trim="item.maxRetail"
                  @keypress="limitNumberValues($event)"
                  :rules="[(v) => !!v || 'Field Required']"
                ></v-text-field>
              </v-col>
              <!-- max retail -->

              <!-- package categories -->
              <v-col cols="12" sm="6" md="4">
                <v-select
                  dense
                  multiple
                  :items="packageCategories"
                  item-text="title"
                  item-value="id"
                  v-model.trim="item.packageCategories"
                  label="Package Categories *"
                  :rules="[(v) => !!v.length || 'Field Required']"
                ></v-select>
              </v-col>
              <!-- package categories -->

              <!-- home page sorting priority -->
              <v-col cols="12" sm="6" md="4">
                <v-select
                  dense
                  :items="[0, 1, 2, 3, 4, 5]"
                  v-model.trim="item.homePageSortingPriority"
                  label="Home Page Sorting Priority *"
                  :rules="[(v) => v !== '' || 'Field Required']"
                ></v-select>
              </v-col>
              <!-- home page sorting priority -->

              <!-- terms and conditions -->
              <v-col cols="12" sm="6" md="4">
                <v-select
                  :items="termsAndConditions"
                  dense
                  v-model="item.terms"
                  @change="(v) => (item.termsAndConditions = v.id)"
                  return-object
                  item-text="title"
                  item-value="id"
                  label="Terms &amp; Conditions *"
                  :rules="[(v) => v.id !== '' || 'Field Required']"
                ></v-select>
              </v-col>
              <!-- terms and conditions -->

              <!-- airfare included -->
              <v-col cols="12" sm="6" md="4">
                <v-select
                  :items="[true, false]"
                  v-model.trim="item.airfareIncluded"
                  label="Airfare Included *"
                  :rules="[(v) => !!v || v === false || 'Field Required']"
                ></v-select>
              </v-col>
              <!-- airfare included -->

              <!-- all inclusive -->
              <v-col cols="12" sm="6" md="4">
                <v-select
                  :items="[true, false]"
                  v-model.trim="item.allInclusive"
                  dense
                  label="All Inclusive *"
                  :rules="[(v) => !!v || v === false || 'Field Required']"
                ></v-select>
              </v-col>
              <!-- all inclusive -->

              <!-- available -->
              <v-col cols="12" sm="6" md="4">
                <v-select
                  dense
                  :items="[true, false]"
                  v-model.trim="item.available"
                  label="Available *"
                  :rules="[(v) => !!v || v === false || 'Field Required']"
                ></v-select>
              </v-col>
              <!-- available -->

              <!-- add additional info -->
              <v-col cols="12" sm="6" md="4">
                <v-select
                  :items="[true, false]"
                  dense
                  v-model.trim="item.addAdditionalInfo"
                  @change="(v) => (!v ? (item.additionalInfo = '') : '')"
                  label="Add Additional Info *"
                  :rules="[(v) => !!v || v === false || 'Field Required']"
                ></v-select>
              </v-col>
              <!-- add additional info -->

              <!-- youtube video url -->
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  label="YouTube Video URL"
                  v-model.trim="item.youtubeVideoUrl"
                ></v-text-field>
              </v-col>
              <!-- youtube video url -->

              <!-- title -->
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Package Title *"
                  dense
                  v-model.trim="item.title"
                  counter
                  :rules="[(v) => !!v || 'Field Required']"
                  hint="Max 49 Characters | Wrap content in <p></p>"
                  @keypress="limitCharacterCount($event, 49)"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <!-- title -->

              <!-- subtitle -->
              <v-col cols="12" sm="8">
                <v-text-field
                  dense
                  label="Package Subtitle *"
                  v-model.trim="item.subtitle"
                  counter
                  :rules="[(v) => !!v || 'Field Required']"
                  hint="Max 76 Characters | Wrap content in <p></p>"
                  @keypress="limitCharacterCount($event, 76)"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <!-- subtitle -->

              <!-- accommodations category -->
              <v-col cols="12" md="4">
                <v-select
                  :items="['condo', 'luxury']"
                  v-model.trim="item.accommodationsCategory"
                  label="Accommodations Category *"
                  dense
                  :rules="[(v) => !!v || 'Field Required']"
                ></v-select>
              </v-col>
              <!-- accommodations category -->

              <!-- additional info -->
              <v-col cols="8" v-if="item.addAdditionalInfo">
                <v-textarea
                  v-model="item.additionalInfo"
                  label="Additional Information"
                  persistent-hint
                  hint="Wrap content in <p></p>"
                  rows="8"
                  :rules="[(v) => !!v || 'Field Required']"
                ></v-textarea>
              </v-col>
              <!-- additional info -->

              <!-- inventory properties -->
              <v-col cols="4">
                <v-autocomplete
                  v-model.trim="item.inventoryProperties"
                  dense
                  :items="inventoryPropertiesItems"
                  item-text="text"
                  item-value="resortPropertyId"
                  label="Inventory Properties *"
                  deletable-chips
                  chips
                  no-data-text="No resorts matching that search"
                  small-chips
                  multiple
                  hint="Type in resort name"
                  persistent-hint
                >
                  <!-- :rules="autocompleteRules" -->
                </v-autocomplete>
              </v-col>
              <!-- inventory properties -->

              <!-- bedrooms -->
              <v-col cols="4">
                <v-text-field
                  dense
                  label="Number of Bedrooms *"
                  v-model.trim="item.bedrooms"
                  :rules="[(v) => !!v || 'Field Required']"
                ></v-text-field>
              </v-col>
              <!-- bedrooms -->

              <!-- avgCost -->
              <v-col cols="4">
                <v-text-field
                  dense
                  label="Average Cost"
                  v-model.trim="item.avgCost"
                  hint="Number w/Decimals 500.50"
                ></v-text-field>
              </v-col>
              <!-- avgCost -->

              <!-- avgUpgrade -->
              <v-col cols="4">
                <v-text-field
                  dense
                  label="Average Upgrade"
                  v-model.trim="item.avgUpgrade"
                  hint="Number w/Decimals 500.50"
                ></v-text-field>
              </v-col>
              <!-- avgUpgrade -->

              <!-- netOutflow -->
              <v-col cols="4">
                <v-text-field
                  dense
                  label="Net Outflow"
                  v-model.trim="item.netOutflow"
                  hint="Number w/Decimals 500.50"
                ></v-text-field>
              </v-col>
              <!-- netOutflow -->

              <!-- avgGrossPl -->
              <v-col cols="4">
                <v-text-field
                  dense
                  label="Avg Gross P/L"
                  v-model.trim="item.avgGrossPl"
                  hint="Number w/Decimals 500.50"
                ></v-text-field>
              </v-col>
              <!-- avgGrossPl -->

              <!-- adminNotes -->
              <v-col cols="6">
                <v-textarea
                  v-model="item.adminNotes"
                  label="Admin Notes"
                  persistent-hint
                  hint="Content does NOT need to be wrapped in <p></p>"
                  rows="4"
                ></v-textarea>
              </v-col>
              <!-- adminNotes -->
              <!-- descriptions -->
              <v-col cols="6">
                <v-textarea
                  v-model="item.description"
                  label="Description"
                  persistent-hint
                  maxlength="200"
                  hint="Description must be within 200 characters"
                  rows="4"
                ></v-textarea>
              </v-col>
              <!-- description -->

              <!-- inventory properties -->

              <!-- <template slot="item" slot-scope="data">
                    <option class="text-capitalize" style="cursor: pointer">
                      {{ data.item.resortName }} - {{ data.item.destination }} -
                      {{ data.item.resortPropertyId }}
                    </option>
                  </template> -->
            </v-row>

            <!--  Upload Video  -->
            <v-row>
              <v-col cols="6">
                <v-toolbar-title class="mt-2 mb-2">
                  <strong>Upload Video</strong>
                  <span class="ml-6"> </span>
                </v-toolbar-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-btn
                  color="primary"
                  :style="{ width: `100%` }"
                  @click="$refs.fileVedio.click()"
                  :loading="loading"
                >
                  Upload Video
                  <input
                    ref="fileVedio"
                    type="file"
                    accept=".mp4"
                    style="display: none"
                    @change="onAddVideo($event.target.files)"
                  />
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="item.video">
              <v-col cols="6">
                <iframe
                  width="560"
                  height="315"
                  :src="item.video.downloadUrl"
                  frameborder="0"
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-col>
            </v-row>
            <!--  Upload Video  -->

            <!-- extras tab -->
            <v-row>
              <v-col cols="12">
                <v-divider class="mt-5" />
              </v-col>
              <v-col cols="12">
                <v-toolbar flat dense>
                  <v-toolbar-title>Extras Tab</v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-btn
                      icon
                      color="primary"
                      @click="
                        item.extrasTab.push({
                          title: '',
                          notes: '',
                        })
                      "
                      ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
                    >
                  </v-toolbar-items>
                </v-toolbar>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                v-for="(item, index) in item.extrasTab"
                :key="index"
              >
                <v-card outlined>
                  <v-card-text>
                    <v-row>
                      <!-- extras title -->
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Title *"
                          v-model.trim="item.title"
                          :rules="[(v) => !!v || 'Field Required']"
                        ></v-text-field>
                      </v-col>
                      <!-- extras title -->

                      <!-- extras notes -->
                      <v-col cols="12">
                        <v-textarea
                          v-model="item.notes"
                          label="Notes"
                          dense
                          :rules="[(v) => !!v || 'Field Required']"
                        ></v-textarea>
                        <div>
                          <strong>Formatted Notes:</strong>
                          <v-card
                            class="mx-auto overflow-auto"
                            height="150"
                            outlined
                          >
                            <p v-html="formatNotes(item.notes)" class="pa-2"></p
                          ></v-card>
                        </div>
                      </v-col>
                      <!-- extras notes -->
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="error"
                      depressed
                      @click="onDeleteExtrasTab(index)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-divider class="mt-3" />
              </v-col>
            </v-row>
            <!-- extras tab -->
            <!-- blurbs -->
            <v-row>
              <v-col cols="12">
                <v-divider class="mt-5" />
              </v-col>
              <v-col cols="12">
                <v-toolbar flat dense>
                  <v-toolbar-title>Blurbs</v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items v-if="item.blurbs.length < 4">
                    <v-btn
                      icon
                      color="primary"
                      @click="
                        item.blurbs.length < 4
                          ? item.blurbs.push({
                              icon: '',
                              title: '',
                              content: '',
                            })
                          : ''
                      "
                      ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
                    >
                  </v-toolbar-items>
                </v-toolbar>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                v-for="(item, index) in item.blurbs"
                :key="index"
              >
                <v-card outlined>
                  <v-card-text>
                    <v-row>
                      <!-- blurb title -->
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Blurb Title *"
                          v-model.trim="item.title"
                          :rules="[(v) => !!v || 'Field Required']"
                          hint="Wrap content in <p></p> and Maximum 68 characters"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                      <!-- blurb title -->
                      <!-- blurb icon -->
                      <v-col cols="12">
                        <v-select
                          item-text="name"
                          item-value="icon"
                          dense
                          :items="sortedBlurbIcons"
                          v-model.trim="item.icon"
                          label="Blurb Icon *"
                          :rules="[
                            (v) => !!v || v === false || 'Field Required',
                          ]"
                        >
                          <!-- selection -->
                          <template slot="selection" slot-scope="data">
                            <v-icon color="primary" class="mr-3">{{
                              data.item.icon
                            }}</v-icon>
                            <span class="text-capitalize">{{
                              data.item.name
                            }}</span>
                          </template>
                          <!-- item -->
                          <template slot="item" slot-scope="data">
                            <template>
                              <v-icon class="mr-3" color="primary">{{
                                data.item.icon
                              }}</v-icon>
                              <span class="text-capitalize">{{
                                data.item.name
                              }}</span>
                            </template>
                          </template>
                          <!-- item -->
                        </v-select>
                      </v-col>
                      <!-- blurb icon -->
                      <!-- blurb content -->
                      <v-col cols="12">
                        <v-textarea
                          v-model="item.content"
                          label="Blurb Content"
                          dense
                          persistent-hint
                          hint="Wrap content in <ul><li></li></ul> and Maximum 190 characters"
                          :rules="[(v) => !!v || 'Field Required']"
                        ></v-textarea>
                      </v-col>
                      <!-- blurb content -->
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      depressed
                      @click="onSortBlurb(index, index - 1)"
                      :class="index == 0 ? 'd-none' : ''"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      color="primary"
                      depressed
                      @click="onSortBlurb(index, index + 1)"
                      :class="
                        item.blurbs
                          ? index == item.blurbs.length - 1
                            ? 'd-none'
                            : ''
                          : ''
                      "
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="error"
                      depressed
                      v-if="index > 0"
                      @click="onDeleteBlurb(index)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-divider class="mt-3" />
              </v-col>
            </v-row>
            <!-- blurbs -->
            <!-- Alternate Image -->
            <v-row>
              <v-col cols="6">
                <v-toolbar-title class="mt-2 mb-2">
                  <strong>Alternate Images</strong>
                  <span class="ml-6"> </span>
                </v-toolbar-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-btn
                  color="primary"
                  :style="{ width: `100%` }"
                  @click="$refs.fileInput.click()"
                  :loading="loading"
                >
                  Upload images
                  <input
                    ref="fileInput"
                    type="file"
                    accept=".jpg, .png, .gif"
                    style="display: none"
                    multiple
                    @change="onAlternateAddImg($event.target.files)"
                  />
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                v-for="(item, index) in item.altImages"
                :key="index"
              >
                <v-card outlined>
                  <v-img :src="item.downloadUrl" cover height="300">
                    <!-- <div
                        v-if="item.addOverlayText && item.overlayText"
                        class="text-center title white--text px-4 py-2 text-uppercase"
                        style="
                          background: rgba(0, 0, 0, 0.75);
                          position: absolute;
                          bottom: 0;
                          left: 0;
                          width: 100%;
                        "
                      >
                        {{ item.overlayText }}
                      </div> -->
                  </v-img>
                  <v-card-text class="text--primary">
                    <v-row>
                      <v-col cols="12" class="body-1 font-weight-medium">
                        Name:
                        <span>{{ item.name }}</span>
                      </v-col>
                      <!-- add overlay text -->
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="[true, false]"
                          v-model.trim="item.addOverlayText"
                          label="Add Overlay Text *"
                          :rules="[
                            (v) => !!v || v === false || 'Field Required',
                          ]"
                        ></v-select>
                      </v-col>
                      <!-- add overlay text -->
                      <!-- overlay text -->
                      <v-col cols="12" v-if="item.addOverlayText">
                        <v-text-field
                          label="Overlay Text *"
                          v-model.trim="item.overlayText"
                          dense
                          :rules="[(v) => !!v || 'Field Required']"
                          counter
                          hint="Max 45 Characters"
                          @keypress="limitCharacterCount($event, 45)"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                      <!-- overlay text -->
                      <!-- download URL -->
                      <v-col cols="12" class="body-1">
                        <span class="d-block mb-2 font-weight-medium"
                          >Image URL:</span
                        >
                        <code v-html="item.downloadUrl" />
                      </v-col>
                      <!-- download URL -->
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      depressed
                      @click="onSortAltImg(index, index - 1)"
                      :class="index == 0 ? 'd-none' : ''"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      color="primary"
                      depressed
                      @click="onSortAltImg(index, index + 1)"
                      :class="
                        item.altImages
                          ? index == item.altImages.length - 1
                            ? 'd-none'
                            : ''
                          : ''
                      "
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="error"
                      depressed
                      @click="onDeleteAltImg(index)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-divider class="mt-5 mb-6" />
              </v-col>
            </v-row>
            <!-- Alternate Image -->
          </v-form>
          <!-- form -->
        </v-card-text>
        <v-card-actions class="sticky-v-card-actions">
          <v-spacer />
          <!-- submit button -->
          <v-btn
            color="primary"
            depressed
            @click="onSubmit"
            :loading="loading"
            :disabled="loading"
          >
            Update Package</v-btn
          >
          <!-- submit button -->
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import firebase from "@/plugins/firebase";
import { mainApp } from "@/firebase";
// import firebase from "firebase/app";
const inventoryProperties = firebase
  .firestore()
  .collection("inventoryProperties");
const packages = mainApp.firestore().collection("packages");
export default {
  async mounted() {
    await this.$store.dispatch("setLoading", true);
  },
  firestore: {
    inventoryProperties,
    packages,
  },
  data: () => ({
    roomTypes: [],
    numberNights: [],
    packageCategories: [],
    termsAndConditions: [],
    inventoryProperties: [],
    packages: [],
    item: {
      accommodationsCategory: "",
      addAdditionalInfo: "",
      additionalInfo: "",
      adminNotes: "",
      airfareIncluded: "",
      allInclusive: "",
      available: "",
      avgCost: "",
      avgGrossPl: "",
      avgUpgrade: "",
      bedrooms: "",
      extrasTab: [],
      blurbs: [],
      costToOrg: "",
      dateUpdated: new Date().toISOString().substring(0, 10),
      destination: "",
      description: "",
      homePageSortingPriority: "",
      id: "",
      images: [],
      altImages: [],
      inventoryProperties: [],
      maxRetail: "",
      netOutflow: "",
      numberNights: "",
      packageCategories: [],
      packageCode: "",
      pdfDownloadUrl: "",
      roomType: {
        id: "",
        title: "",
      },
      socialSharingImage: "",
      subtitle: "",
      terms: {
        content: "",
        id: "",
        title: "",
      },
      termsAndConditions: "",
      title: "",
      youtubeVideoUrl: "",
      video: "",
    },
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    inventoryPropertiesItems() {
      return this.inventoryProperties.map((i) => {
        let item = {
          resortPropertyId: i.resortPropertyId,
          text:
            i.resortPropertyId + " - " + i.resortName + " - " + i.destination,
        };
        return item;
      });
    },
    imageRules() {
      if (!this.item.images.length) {
        return [false];
      } else {
        if (this.item.images.length < 4) {
          return [false];
        }
      }
    },
    autocompleteRules() {
      if (
        !this.item.inventoryProperties ||
        !this.item.inventoryProperties.length
      ) {
        return [false];
      } else {
        return [true];
      }
    },
    sortedBlurbIcons() {
      const blurbIcons = [
        {
          name: "bullet",
          icon: "mdi-circle-small",
        },
        {
          name: "information",
          icon: "mdi-information",
        },
        {
          name: "star",
          icon: "mdi-star",
        },
        {
          name: "city",
          icon: "mdi-city-variant",
        },
        {
          name: "airplane",
          icon: "mdi-airplane",
        },
        {
          name: "award",
          icon: "mdi-seal-variant",
        },
        {
          name: "food and drink",
          icon: "mdi-food-fork-drink",
        },
        {
          name: "music",
          icon: "mdi-music",
        },
        {
          name: "music circle",
          icon: "mdi-music-circle-outline",
        },
        {
          name: "museum",
          icon: "mdi-bank",
        },
        {
          name: "smile",
          icon: "mdi-emoticon-happy",
        },
        {
          name: "thumbs up",
          icon: "mdi-thumb-up",
        },
        {
          name: "pool",
          icon: "mdi-pool",
        },
        {
          name: "swim",
          icon: "mdi-swim",
        },
        {
          name: "snorkel",
          icon: "mdi-diving-snorkel",
        },
        {
          name: "scuba",
          icon: "mdi-diving-scuba-tank",
        },
        {
          name: "beach",
          icon: "mdi-beach",
        },
        {
          name: "lifeguard",
          icon: "mdi-tower-beach",
        },
        {
          name: "pets",
          icon: "mdi-paw",
        },
        {
          name: "fish",
          icon: "mdi-fish",
        },
        {
          name: "grill",
          icon: "mdi-grill",
        },
        {
          name: "bar",
          icon: "mdi-glass-mug-variant",
        },
        {
          name: "binoculars",
          icon: "mdi-binoculars",
        },
        {
          name: "bed",
          icon: "mdi-bed",
        },
        {
          name: "flower",
          icon: "mdi-flower-tulip",
        },
        {
          name: "pizza",
          icon: "mdi-pizza",
        },
        {
          name: "lake",
          icon: "mdi-waves",
        },
        {
          name: "mountain",
          icon: "mdi-terrain",
        },
        {
          name: "water ski",
          icon: "mdi-ski-water",
        },
        {
          name: "ski",
          icon: "mdi-ski",
        },
        {
          name: "snow",
          icon: "mdi-snowflake",
        },
        {
          name: "sun",
          icon: "mdi-weather-sunny",
        },
        {
          name: "forest",
          icon: "mdi-pine-tree",
        },
        {
          name: "castle",
          icon: "mdi-castle",
        },
        {
          name: "golf",
          icon: "mdi-golf",
        },
        {
          name: "car",
          icon: "mdi-car",
        },
        {
          name: "convertible",
          icon: "mdi-car-convertible",
        },
      ];
      return blurbIcons.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    },
  },
  methods: {
    formatNotes(notes) {
      const urlRegex = /((https?|ftp):\/\/[^\s/$.?#].[^\s]*)/g;
      const text = notes.replace(/\n/g, "\n <br>");
      return text.replace(urlRegex, function(url) {
        if (url.match(/^https?:\/\//)) {
          return `<a href="${url}" target="_blank">${url}</a>`;
        } else {
          return url;
        }
      });
    },
    async onSubmit() {
      // if (!this.item.altImages || this.item.altImages.length < 2) {
      //   await this.$store.dispatch("setLoading", false);
      //   await this.$store.dispatch("setSnackbar", {
      //     display: true,
      //     color: "error",
      //     text: "Please upload atleast 2 Alternative Image",
      //     timeout: 10000,
      //   });
      //   return;
      // }
      if (this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", true);
        // format data
        this.item.bedrooms = parseInt(this.item.bedrooms);
        // upload images & get downloadURL
        let i;
        for (i = 0; i < this.item.images.length; i++) {
          let image = this.item.images[i];
          if (image.downloadUrl.toLowerCase().includes("blob")) {
            let imageRef = mainApp
              .storage()
              .ref(`packages/${this.item.packageCode}/${image.name}`);
            let imageUploaded = await imageRef.put(image.file);
            image.downloadUrl = await imageUploaded.ref.getDownloadURL();
            delete image.file;
          }
        }

        // upload video
        if (
          this.item.video &&
          this.item.video.downloadUrl.toLowerCase().includes("blob")
        ) {
          let video = this.item.video;
          let videoRef = mainApp
            .storage()
            .ref(`packageVideos/${this.item.packageCode}/${video.name}`);
          let videoUploaded = await videoRef.put(video.file);
          video.downloadUrl = await videoUploaded.ref.getDownloadURL();
          delete video.file;
        }

        // upload alternative images & get downloadURL
        if (this.item.altImages && this.item.altImages.length) {
          let j;
          for (j = 0; j < this.item.altImages.length; j++) {
            let image = this.item.altImages[j];
            if (image.downloadUrl.toLowerCase().includes("blob")) {
              let imageRef = mainApp
                .storage()
                .ref(`packages/${this.item.packageCode}/${image.name}`);
              let imageUploaded = await imageRef.put(image.file);
              image.downloadUrl = await imageUploaded.ref.getDownloadURL();
              delete image.file;
            }
          }
        }

        // update firestore package doc
        let packageDocRef = mainApp
          .firestore()
          .collection("packages")
          .doc(this.item.packageCode);
        await packageDocRef.update(this.item);

        // update package PDF && local item
        let pdfResponse = await axios.post(
          "https://savrestapi.com/savadmin/create-package-pdf",
          this.item
        );
        this.item.pdfDownloadUrl = pdfResponse.data.pdfDownloadUrl;

        // update firestore package doc
        await packageDocRef.update({
          pdfDownloadUrl: pdfResponse.data.pdfDownloadUrl,
        });

        // update package guide PDF
        // filtering to only available packages
        let onlyAvailablePackages = this.packages.filter((i) => i.available);
        let packageGuidePackages = [];
        let item;
        for (item = 0; item < onlyAvailablePackages.length; item++) {
          let packageToUpdate = onlyAvailablePackages[item];
          packageToUpdate.costToOrg = "$" + packageToUpdate.costToOrg + ".00";
          packageToUpdate.maxRetail = "$" + packageToUpdate.maxRetail + ".00";
          packageGuidePackages.push(packageToUpdate);
          if (onlyAvailablePackages.length == packageGuidePackages.length) {
            await axios.post(
              "https://savrestapi.com/savadmin/create-package-guide-pdf",
              {
                packages: packageGuidePackages,
              }
            );

            // update package in portalsApp
            let portalsAppPackageDocRef = firebase
              .firestore()
              .collection("retailPackages")
              .doc(this.item.packageCode);
            await portalsAppPackageDocRef.update(this.item);

            await this.$store.dispatch("setLoading", false);
            this.handleSuccess("Package Updated Successfully");
            this.$router.push("/packages");
          }
        }
      }
      await this.$store.dispatch("setLoading", false);
      return;
    },
    formatPackageCode(value) {
      this.item.packageCode = value.toLowerCase().replace(" ", "");
    },
    onSortBlurb(from, to) {
      this.item.blurbs.splice(to, 0, this.item.blurbs.splice(from, 1)[0]);
    },
    onDeleteExtrasTab(index) {
      this.item.extrasTab.splice(index, 1);
    },
    onDeleteBlurb(index) {
      this.item.blurbs.splice(index, 1);
    },
    onSortImg(from, to) {
      this.item.images.splice(to, 0, this.item.images.splice(from, 1)[0]);
    },
    onSortAltImg(from, to) {
      this.item.altImages.splice(to, 0, this.item.altImages.splice(from, 1)[0]);
    },
    onAddVideo(files) {
      if (files) {
        let file = files[0];
        let video = {
          file: file,
          name: file.name.replace(" ", "-"),
          downloadUrl: URL.createObjectURL(file),
          dateSubmitted: new Date().toISOString(),
        };
        this.item.video = video;
      }
    },
    onAlternateAddImg(files) {
      if (files) {
        // console.log(files);
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          let img = {
            file: file,
            name: file.name.replace(" ", "-"),
            downloadUrl: URL.createObjectURL(file),
            addOverlayText: false,
            overlayText: "",
            dateSubmitted: new Date().toISOString(),
          };
          this.item.altImages = this.item.altImages
            ? [...this.item.altImages, img]
            : [img];
        }
      }
    },
    onAddImg(file) {
      if (file && this.item.images.length < 4) {
        let img = {
          file: file,
          name: file.name.replace(" ", "-"),
          downloadUrl: URL.createObjectURL(file),
          addOverlayText: false,
          overlayText: "",
          dateSubmitted: new Date().toISOString(),
        };
        this.item.images.push(img);
      }
    },
    onDeleteImg(index) {
      this.item.images.splice(index, 1);
    },
    onDeleteAltImg(index) {
      this.item.altImages.splice(index, 1);
    },
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      async handler(packageID) {
        let packageRequest = await axios.post("/api/get-retail-package", {
          packageID,
        });

        // map data
        let retailPackage = packageRequest.data.packageData;
        // mount retailPackage item
        this.item.accommodationsCategory = retailPackage.accommodationsCategory;
        this.item.addAdditionalInfo = retailPackage.addAdditionalInfo;
        this.item.additionalInfo = retailPackage.additionalInfo;
        this.item.adminNotes = retailPackage.adminNotes;
        this.item.airfareIncluded = retailPackage.airfareIncluded;
        this.item.allInclusive = retailPackage.allInclusive;
        this.item.available = retailPackage.available;
        this.item.avgCost = retailPackage.avgCost;
        this.item.avgGrossPl = retailPackage.avgGrossPl;
        this.item.avgUpgrade = retailPackage.avgUpgrade;
        this.item.bedrooms = retailPackage.bedrooms;
        this.item.extrasTab = retailPackage.extrasTab
          ? retailPackage.extrasTab
          : [];
        this.item.blurbs = retailPackage.blurbs;
        this.item.costToOrg = retailPackage.costToOrg;
        this.item.destination = retailPackage.destination;
        this.item.description = retailPackage.description || "";
        this.item.homePageSortingPriority =
          retailPackage.homePageSortingPriority;
        this.item.id = retailPackage.id;
        this.item.images = retailPackage.images;
        this.item.altImages = retailPackage.altImages || [];
        this.item.inventoryProperties = retailPackage.inventoryProperties;
        this.item.maxRetail = retailPackage.maxRetail;
        this.item.netOutflow = retailPackage.netOutflow;
        this.item.numberNights = retailPackage.numberNights;
        this.item.packageCategories = retailPackage.packageCategories;
        this.item.packageCode = retailPackage.packageCode;
        this.item.pdfDownloadUrl = retailPackage.pdfDownloadUrl;
        this.item.roomType = retailPackage.roomType;
        this.item.socialSharingImage = retailPackage.socialSharingImage;
        this.item.subtitle = retailPackage.subtitle;
        this.item.terms = retailPackage.terms;
        this.item.termsAndConditions = retailPackage.termsAndConditions;
        this.item.title = retailPackage.title;
        this.item.youtubeVideoUrl = retailPackage.youtubeVideoUrl;
        this.item.video = retailPackage.video || "";
        // get && set roomTypes
        let roomTypesRequest = await axios.get("/api/get-room-types");
        this.roomTypes = roomTypesRequest.data.data;
        // get && set numberNights
        let numberNightsRequest = await axios.get("/api/get-number-nights");
        this.numberNights = numberNightsRequest.data.data;
        // get && set packageCategories
        let packageCategoriesRequest = await axios.get(
          "/api/get-package-categories"
        );
        this.packageCategories = packageCategoriesRequest.data.data;
        // get && set termsAndConditionsItems
        let termsAndConditionsRequest = await axios.get(
          "/api/get-terms-and-conditions"
        );
        this.termsAndConditions = termsAndConditionsRequest.data.data;
        // success
        await this.$store.dispatch("setLoading", false);
      },
    },
  },
};
</script>
